// $(document).ready(function(){
// 	$("").onClick(function(){
// 		$().fadeIn(600);
// 	});
// });

//drop down menu mobile
$(document).ready(function(){
	$('.soj-navbar-button-icon').click(function(){
		//$('.moo-sda-nav__links-mobile-drop').slideToggle(300);
		$('.soj-navbar').fadeToggle(400);
	});
	
});

//drop down prayer button
// $(document).ready(function(){
// 	$('.moo-sda-nav__prayer').click(function(){
// 		//$('.moo-sda-nav__links-mobile-drop').slideToggle(300);
// 		$('.moo-sda-prayer__form-container').fadeIn(200);
// 	});
	
// });

//drop down prayer close button
// $(document).ready(function(){
// 	$('.moo-sda-prayer__form-close').click(function(){
// 		//$('.moo-sda-nav__links-mobile-drop').slideToggle(300);
// 		$('.moo-sda-prayer__form-container').fadeOut(200);
// 	});
	
// });

//
// $(document).ready(function(){

// 	$( ".moo-sda-service__content" ).load( "http://localhost/wordpress/wp-content/themes/moosda/shem_fw/content/SabbathSchool.php" );
// 	$('.moo-sda-service__links li a').click(function(){
// 		var page = $(this).attr('href');
// 		$('.moo-sda-service__content').load('http://localhost/wordpress/wp-content/themes/moosda/shem_fw/content/'+ page +'.php');
// 	return false;
// 	})
// });

//load mobile content

// $(document).ready(function(){

// 	$( ".service__mobileLoad-content" ).load( "http://localhost/wordpress/wp-content/themes/moosda/shem_fw/content/SabbathSchool.php" );
// 	$('.service__mobileLoad-Links li a').click(function(){
// 		var page = $(this).attr('href');
// 		$('.service__mobileLoad-content').load('http://localhost/wordpress/wp-content/themes/moosda/shem_fw/content/'+ page +'.php');
// 	return false;
// 	})
// });

//load mobile content 2
// $(document).ready(function(){

// 	$( ".service__mobileLoad-content2" ).load( "http://localhost/wordpress/wp-content/themes/moosda/shem_fw/content/AyService.php" );
// 	$('.service__mobileLoad-Links2 li a').click(function(){
// 		var page = $(this).attr('href');
// 		$('.service__mobileLoad-content2').load('http://localhost/wordpress/wp-content/themes/moosda/shem_fw/content/'+ page +'.php');
// 	return false;
// 	})
// });

// //load transportation content
// $(document).ready(function(){

// 	$( ".moo-sda-location__transportation-content" ).load( "http://localhost/wordpress/wp-content/themes/moosda/shem_fw/transportation/Train.php" );
// 	$('.moo-sda-location__links li a').click(function(){
// 		var page = $(this).attr('href');
// 		$('.moo-sda-location__transportation-content').load('http://localhost/wordpress/wp-content/themes/moosda/shem_fw/transportation/'+ page +'.php');
// 	return false;
// 	})
// });



//the map cordinates.
// var map;
//     $(document).ready(function(){
//       map = new GMaps({
//         el: '.moo-sda-location__map',
//         lat: 40.692330,
//         lng: -73.922512,
//         zoomControl : true,
//         zoom: 18,
//         zoomControlOpt: {
//             style : 'SMALL',
//             position: 'TOP_RIGHT'
//         },
//         panControl : false,
//         streetViewControl : false,
//         mapTypeControl: false,
//         overviewMapControl: false
//       });
//     });

// alert('hey');

//a href scroller

// $(document).ready(function(){
// 	$('a[href^="#"]').on('click',function (e) {
// 	    e.preventDefault();

// 	    var target = this.hash;
// 	    var $target = $(target);

// 	    $('html, body').stop().animate({
// 	        'scrollTop': $target.offset().top
// 	    }, 800, 'swing', function () {
// 	        window.location.hash = target;
// 	    });
// 	});
// });


//button appears
$(function(){
    var d = $('.soj-slide-up-button');
    var dPosTop = d.offset().top;
    var win = $(window);
    win.scroll(function(e){
        var scrollTop = win.scrollTop();
        if(scrollTop > dPosTop){
          d.fadeIn(600);
        }
      else{
        d.fadeOut(400);
      }
    });

});
//scrolls up page
$(document).ready(function(){
	$('.soj-slide-up-button').click(function(){
		$('body').test();
	});
});
